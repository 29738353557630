<template>
    <div class="flex size-full touch-none flex-col bg-white text-neutral-800 dark:bg-black dark:text-neutral-100">
        <router-view v-slot="{ Component }" class="size-full p-2 xl:p-4">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>
        <camera-container></camera-container>
        <footer-toolbar></footer-toolbar>
    </div>
</template>

<script lang="ts" setup>
    import FooterToolbar from "@/components/footer/FooterToolbar.vue";
    import CameraContainer from "@/components/CameraContainer.vue";

    // this is a workaround to prevent the user from scrolling the page accidentally
    // this is especially required for iPad in PWA mode where double tapping causes the page to scroll
    // https://stackoverflow.com/questions/55120331/white-panel-arrives-on-double-tap-from-bottom-in-pwa-in-standalone-mode
    document.addEventListener("scroll", () => {
        if (document.documentElement.scrollTop > 1) {
            document.documentElement.scrollTop = 100;
        }
    });
</script>
