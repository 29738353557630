<template>
    <!-- Info: Don't use gap here; Sotec TDx does not support it -->
    <div
        class="flex w-full flex-col flex-wrap items-center justify-center bg-neutral-200 p-1 text-neutral-200 *:my-1 lg:flex-row lg:justify-between xl:p-2 xl:text-xl dark:bg-neutral-800 dark:text-neutral-100"
    >
        <div class="flex flex-row">
            <button class="mx-1 flex items-center justify-center px-2 py-1 text-4xl text-secondary xl:text-4xl dark:text-neutral-100" @click="forceReload">
                <ion-icon :icon="refreshCircle"></ion-icon>
            </button>

            <device-info-button v-if="deviceStore.isAuthenticated" />

            <language-select />
        </div>

        <div class="flex flex-row flex-wrap justify-center *:m-1">
            <footer-info-chip v-if="deviceStore.deviceConfig?.location" class="bg-neutral-500">
                {{ deviceStore.deviceConfig.location }}
            </footer-info-chip>
            <footer-info-chip class="bg-neutral-500">v{{ appVersion }}</footer-info-chip>
            <connection-status />
        </div>
    </div>
</template>

<script lang="ts" setup>
    // Components
    import ConnectionStatus from "@/components/ConnectionStatus.vue";
    import DeviceInfoButton from "@/components/DeviceInfoButton.vue";
    import FooterInfoChip from "@/components/footer/FooterInfoChip.vue";
    import LanguageSelect from "@/components/footer/LanguageSelect.vue";

    import { IonIcon } from "@ionic/vue";
    import { useDeviceStore } from "@/stores/device.store";
    import { refreshCircle } from "ionicons/icons";

    const forceReload = () => {
        location.reload();
    };

    const deviceStore = useDeviceStore();

    const appVersion = import.meta.env.PACKAGE_VERSION;
</script>

<style scoped></style>
