<template>
    <div class="flex items-center">
        <img class="mr-2 inline size-8" :src="getFlagIconUrl" :alt="`${props.locale} flag`" />
        <span class="text-2xl">{{ t(`locale.${props.locale}`) }}</span>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { useI18n } from "vue-i18n";

    const { t } = useI18n({ useScope: "global" });

    const props = defineProps<{
        locale: string;
    }>();

    const getFlagIconUrl = computed(() => {
        return new URL(`/resources/language_icons/${props.locale}.svg`, import.meta.url).href;
    });
</script>
