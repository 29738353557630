<template>
    <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
    <div v-if="cameraStore.isCameraFeatureActive" class="camera-container absolute bottom-14 left-8 size-20 xl:bottom-16">
        <button v-show="cameraStore.isCameraLoading === true" class="flex size-full items-center justify-center rounded-xl bg-red-700 text-neutral-100" @click="cameraStore.startCamera">
            <ion-icon :icon="warning" class="text-2xl"></ion-icon>
        </button>
        <div v-show="cameraStore.isCameraActive === true" class="rounded-xl">
            <camera
                ref="myCamera"
                :resolution="{ width: 500, height: 500 }"
                facing-mode="user"
                @loading="cameraStore.loading"
                @started="cameraStore.setIsActive(true)"
                @stopped="cameraStore.setIsActive(false)"
                @paused="cameraStore.setIsActive(false)"
                @resumed="cameraStore.setIsActive(true)"
                @error="cameraStore.handleCameraError"
            >
            </camera>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from "vue";
    import { IonIcon } from "@ionic/vue";
    import Camera from "simple-vue-camera";
    import { useCameraStore } from "@/stores/camera.store";
    import { warning } from "ionicons/icons";

    const cameraStore = useCameraStore();

    const myCamera = ref<InstanceType<typeof Camera>>();

    watch(myCamera, () => {
        cameraStore.setCameraRef(myCamera.value);
    });
</script>

<style scoped>
    .camera-container :deep(#video) {
        border-radius: 10px;
    }
</style>
