<template>
    <button class="mx-1 flex items-center justify-center whitespace-nowrap rounded-sm px-2 py-1 text-4xl text-secondary xl:text-4xl dark:text-neutral-100" @click="showModal">
        <ion-icon :icon="informationCircle"></ion-icon>
    </button>

    <info-modal v-model:is-open="isModalOpen" :title="t('deviceInfo.title')" class="text-base">
        <template #body>
            <div class="grid grid-cols-1 rounded-md text-neutral-600 dark:text-neutral-200">
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.appVersion") }}</div>
                    <div class="ml-auto">{{ appVersion }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.deviceName") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.location }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.deviceId") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.deviceId }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.structureNodeId") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.structureNodeId }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.pinLength") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.pinLength }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.showBreakButtons") }}</div>
                    <div class="ml-auto">{{ booleanPrintString(deviceStore.deviceConfig?.showBreakButtons ?? false) }}</div>
                </div>
                <div class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.takePicture") }}</div>
                    <div class="ml-auto">{{ booleanPrintString(deviceStore.deviceConfig?.takePictureWhenClockingInOrOut ?? false) }}</div>
                </div>
                <div v-if="lastUpdatedDate" class="flex items-center p-2 text-gray-500 odd:bg-neutral-600 odd:text-gray-300 dark:text-gray-300">
                    <div>{{ t("deviceInfo.lastUpdate") }}</div>
                    <div class="ml-auto">{{ $d(lastUpdatedDate, "long") }}</div>
                </div>
            </div>
        </template>
        <template #footer>
            <reset-device-button v-model:is-open="isResetModalOpen"></reset-device-button>
        </template>
    </info-modal>
</template>

<script lang="ts" setup>
    import { computed, onBeforeUnmount, ref } from "vue";
    import { useDeviceStore } from "@/stores/device.store";
    import ResetDeviceButton from "@/components/ResetDeviceButton.vue";
    import { useAutoDismiss } from "@/services/auto-dismiss.service";
    import InfoModal from "@/components/modals/InfoModal.vue";
    import { useI18n } from "vue-i18n";
    import { IonIcon } from "@ionic/vue";
    import { informationCircle } from "ionicons/icons";

    const { t } = useI18n({ useScope: "global" });
    const isModalOpen = ref(false);
    const isResetModalOpen = ref(false);
    const deviceStore = useDeviceStore();

    const appVersion = import.meta.env.PACKAGE_VERSION;

    const closeModal = async () => {
        isResetModalOpen.value = false;
        isModalOpen.value = false;
        cancelTimeout();
    };

    const { resetTimeout, cancelTimeout } = useAutoDismiss(closeModal, 30);

    const showModal = () => {
        isModalOpen.value = true;
        resetTimeout();
    };

    function booleanPrintString(value: boolean): string {
        return value ? t("yes") : t("no");
    }

    const lastUpdatedDate = computed(() => {
        if (!deviceStore.deviceConfig) return undefined;
        return deviceStore.deviceConfig?.updatedAt.toDate();
    });

    onBeforeUnmount(() => {
        cancelTimeout();
    });
</script>

<style scoped></style>
