<template>
    <!-- Info: Don't use gap here; Sotec TDx does not support it -->
    <div class="flex w-full flex-row items-center justify-between bg-neutral-200 p-4 text-neutral-200 xl:p-4 xl:text-xl dark:bg-neutral-800 dark:text-neutral-100">
        <div class="flex flex-row">
            <button class="mx-1 flex items-center justify-center px-2 py-1 text-2xl text-secondary xl:text-4xl dark:text-neutral-100" @click="forceReload">
                <ion-icon :icon="refreshCircle"></ion-icon>
            </button>

            <device-info-button v-if="deviceStore.isAuthenticated" />

            <select id="countries" v-model="locale" class="mx-1 block rounded-sm border-0 bg-transparent px-2 py-1 text-neutral-800 dark:text-neutral-200">
                <!-- FIXME TDx kann keine Language emojis -->
                <option v-for="availableLocale in availableLocales" :key="`locale-${availableLocale}`" :value="availableLocale" class="bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-100">
                    {{ t(`locale.${availableLocale}`) }}
                </option>
            </select>
        </div>

        <div class="*:mx-1">
            <footer-info-chip v-if="deviceStore.deviceConfig?.location" class="bg-neutral-500">
                {{ deviceStore.deviceConfig.location }}
            </footer-info-chip>
            <footer-info-chip class="bg-neutral-500">v{{ appVersion }}</footer-info-chip>
            <connection-status />
        </div>
    </div>
</template>

<script lang="ts" setup>
    // Components
    import ConnectionStatus from "@/components/ConnectionStatus.vue";
    import DeviceInfoButton from "@/components/DeviceInfoButton.vue";
    import FooterInfoChip from "@/components/footer/FooterInfoChip.vue";

    import { IonIcon } from "@ionic/vue";
    import { useDeviceStore } from "@/stores/device.store";
    import { refreshCircle } from "ionicons/icons";
    import { useI18n } from "vue-i18n";

    const { locale, t, availableLocales } = useI18n({ useScope: "global" });

    const forceReload = () => {
        location.reload();
    };

    const deviceStore = useDeviceStore();

    const appVersion = import.meta.env.PACKAGE_VERSION;
</script>

<style scoped></style>
