import { createI18n } from "vue-i18n";
import en from "./languages/en.json";
import de from "./languages/de.json";
import pl from "./languages/pl.json";
import numberFormats from "@/locales/numberFormats";
import datetimeFormats from "@/locales/dateFormats";

const i18n = createI18n({
    locale: localStorage.getItem("locale") || "de", // fixme: use storage service somehow
    fallbackLocale: "en",
    preserveDirectiveContent: true,
    globalInjection: true,
    messages: { en, de, pl },
    legacy: false,
    numberFormats,
    datetimeFormats,
});

export function setLocale(newLocale: string) {
    console.debug(`Changing locale to ${newLocale} and saving it to storage`);
    i18n.global.locale.value = newLocale;
    localStorage.setItem("locale", newLocale); // fixme: use storage service somehow
}

export { i18n };
