<template>
    <button class="mx-1 flex items-center justify-center px-2 py-1 text-secondary dark:text-neutral-100" @click="isLanguageSelectModalOpen = true">
        <language-with-flag :locale="locale" />
    </button>

    <div v-if="isLanguageSelectModalOpen" class="fixed left-0 top-0 z-50 flex size-full items-center justify-center overflow-y-auto overflow-x-hidden bg-neutral-700/50 dark:bg-neutral-700/80">
        <div class="relative max-h-full w-full max-w-4xl p-4 text-neutral-700 dark:text-neutral-100">
            <!-- Modal content -->
            <div class="relative rounded-lg bg-neutral-100 shadow dark:bg-neutral-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between rounded-t border-b border-neutral-300 p-4 md:p-5 dark:border-neutral-600">
                    <ion-icon :icon="language" class="mr-2" size="large"></ion-icon>
                    <span class="text-2xl font-semibold">{{ t("languageSelect") }}</span>

                    <button
                        class="ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-transparent text-sm text-neutral-800 dark:text-neutral-400"
                        @click="isLanguageSelectModalOpen = false"
                    >
                        <ion-icon :icon="closeOutline" size="large" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="flex flex-col space-y-4 p-4 text-xl md:p-5">
                    <button
                        v-for="eachLocale in availableLocales"
                        :key="`locale-${eachLocale}`"
                        :value="eachLocale"
                        class="flex justify-center rounded-xl bg-neutral-200 p-4 dark:bg-neutral-800 dark:text-neutral-100"
                        @click="changeLocaleTo(eachLocale)"
                    >
                        <language-with-flag :locale="eachLocale" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useI18n } from "vue-i18n";
    import { ref } from "vue";

    import LanguageWithFlag from "@/components/LanguageWithFlag.vue";
    import { IonIcon } from "@ionic/vue";

    import { language, closeOutline } from "ionicons/icons";

    import { setLocale } from "@/locales/i18n";

    const { locale, t, availableLocales } = useI18n({ useScope: "global" });

    const isLanguageSelectModalOpen = ref(false);

    function changeLocaleTo(newLocale: string): void {
        setLocale(newLocale);
        isLanguageSelectModalOpen.value = false;
    }
</script>
