import { computed, ref } from "vue";
import Camera from "simple-vue-camera";
import * as Sentry from "@sentry/vue";
import { useDeviceStore } from "@/stores/device.store";
import { usePermission } from "@vueuse/core";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";

const CAMERA_RESOLUTION = { width: 500, height: 500 };
const CAMERA_QUALITY = 0.5;

export const useCameraStore = defineStore("camera", () => {
    const deviceStore = useDeviceStore();
    const isCameraActive = ref(false);
    const isCameraLoading = ref(false);
    const cameraRef = ref<InstanceType<typeof Camera>>();

    const { t } = useI18n({ useScope: "global" });

    const takePicture = async () => {
        try {
            const cameraReference = cameraRef.value;
            if (!cameraReference) {
                throw new Error("Could not find camera");
            }

            const cameraPictureBlob = await cameraReference.snapshot(CAMERA_RESOLUTION, "image/png", CAMERA_QUALITY);
            if (!cameraPictureBlob) {
                return undefined;
            }

            const pictureAsBase64 = await blobToBase64(cameraPictureBlob);
            return pictureAsBase64;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
        }
    };

    const blobToBase64 = (pictureBlob: Blob) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(pictureBlob);
        });
    };

    const startCamera = async () => {
        console.debug("Starting camera...");
        if (!deviceStore.isAuthenticated) {
            return;
        }
        const cameraPermissionState = usePermission("camera");
        if (isCameraActive.value && cameraPermissionState.value === "granted") {
            return;
        }

        if (!cameraRef.value?.stream) {
            try {
                await cameraRef.value?.start();
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
                location.reload();
            }
        }
    };

    const isCameraFeatureActive = computed(() => {
        return deviceStore.deviceConfig?.takePictureWhenClockingInOrOut ?? false;
    });

    const handleCameraError = async (error: Error) => {
        Sentry.captureException(error);
        console.error(error);

        toast(t("camera.permissionError"), { type: "error" });
    };

    const setCameraRef = (cam: InstanceType<typeof Camera> | undefined) => {
        cameraRef.value = cam;
    };

    const loading = () => {
        isCameraLoading.value = true;
    };

    const setIsActive = (isActive: boolean) => {
        isCameraLoading.value = false;
        isCameraActive.value = isActive;
    };

    return {
        loading,
        setIsActive,
        setCameraRef,
        isCameraActive,
        isCameraLoading,
        handleCameraError,
        takePicture,
        isCameraFeatureActive,
        startCamera,
    };
});
