<template>
    <div class="size-full">
        <div :class="timeRecordingModeColorClass" class="mb-4 flex items-center justify-center rounded-md p-2 text-5xl text-neutral-100 xl:p-4 xl:text-6xl">
            <ion-icon :icon="timeRecordingModeIcon" class="mr-2" size="large"></ion-icon>
            <span class="mr-4">{{ t(`timeRecordingMode.${timeRecordingMode}`) }}</span>
            <current-time-clock />
        </div>

        <div class="flex h-3/4 flex-col items-center justify-center">
            <div class="my-4 flex space-x-3">
                <input
                    v-for="i in submitThreshold"
                    :key="i - 1"
                    v-model="pin[i - 1]"
                    class="block h-16 w-12 rounded-md border-2 border-neutral-600 bg-neutral-100 text-center text-5xl dark:bg-neutral-800"
                    type="password"
                />
            </div>

            <pin-entry-keyboard :disabled="!isPinEntryReady" class="mt-2 w-full max-w-screen-md" @cancel="cancel" @add-digit="addDigit" @remove-last-digit="removeLastDigit" />
        </div>

        <successful-recorded-times-modal v-model:is-open="isSuccessRecordedTimeModalOpen" type="success" :recorded-assignments="recordedAssignments" />
        <record-time-error-modal v-if="error" v-model:is-open="isRecordTimeErrorModalOpen" type="error" :axios-error="error" :time-recording-mode="timeRecordingMode" />
    </div>
</template>

<script lang="ts" setup>
    // Components
    import { IonIcon } from "@ionic/vue";
    import PinEntryKeyboard from "@/components/PinEntryKeyboard.vue";
    import SuccessfulRecordedTimesModal from "@/components/modals/SuccessfulRecordedTimesModal.vue";
    import RecordTimeErrorModal from "@/components/modals/RecordTimeErrorModal.vue";
    import CurrentTimeClock from "@/components/CurrentTimeClock.vue";
    import { cafe, home, logIn, logOut } from "ionicons/icons";

    import { useDeviceStore } from "@/stores/device.store";
    import { computed, onMounted, ref, watch } from "vue";
    import { RecordTimesMode } from "@/interfaces/record-times-mode.enum";
    import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
    import { useRecordTime } from "@/services/record-time.service";
    import { AxiosError } from "axios";
    import { RecordedAssignmentWithEmployeeNameDto } from "@/services/api/services";
    import { RecordTimeErrorCode } from "@/interfaces/record-time-error-codes.enum";
    import { useI18n } from "vue-i18n";
    import { useConnectionState } from "@/services/connection-state.service";
    import { useAutoDismiss } from "@/services/auto-dismiss.service";
    import { toast } from "vue3-toastify";
    import { useCameraStore } from "@/stores/camera.store";

    const { t } = useI18n({ useScope: "global" });
    const deviceStore = useDeviceStore();
    const recordTimeService = useRecordTime();
    const router = useRouter();
    const route = useRoute();
    const recordedAssignments = ref<RecordedAssignmentWithEmployeeNameDto[] | undefined>();
    const isSuccessRecordedTimeModalOpen = ref(false);
    const isRecordTimeErrorModalOpen = ref(false);
    const error = ref<AxiosError | undefined>();

    const cancel = async () => {
        isSuccessRecordedTimeModalOpen.value = false;
        isRecordTimeErrorModalOpen.value = false;
        clearPin();
        await router.replace({ name: "RecordTimes" });
    };

    const { resetTimeout, cancelTimeout } = useAutoDismiss(cancel, 30);
    const isSubmitting = ref(false);

    const { isConnected } = useConnectionState();
    const cameraStore = useCameraStore();

    const isPinEntryReady = computed(() => {
        if (isSubmitting.value) return false;
        if (!isConnected.value) return false;
        if (shouldTakePicture.value) {
            return cameraStore.isCameraActive;
        }

        return true;
    });

    watch(isSuccessRecordedTimeModalOpen, (newValue) => {
        if (newValue === false) {
            cancel();
        }
    });

    watch(isRecordTimeErrorModalOpen, (newValue) => {
        if (!newValue) {
            didCloseErrorModal();
        }
    });

    onMounted(() => {
        resetTimeout();
        cameraStore.startCamera();
    });

    watch(
        () => route.path,
        () => {
            if (route.path.includes("pinEntry")) resetTimeout();
        },
    );

    onBeforeRouteLeave((to, from, next) => {
        cancelTimeout();
        next(); // Call next() to proceed with the route navigation
    });

    const pin = ref("");

    const addDigit = (digit: string) => {
        pin.value += digit;
        resetTimeout();
    };

    const removeLastDigit = () => {
        if (pin.value.length === 0) return;
        pin.value = pin.value.slice(0, -1);
        resetTimeout();
    };

    const clearPin = () => {
        pin.value = "";
        recordedAssignments.value = undefined;
    };

    const timeRecordingMode = computed(() => router.currentRoute.value.query.mode as RecordTimesMode);

    const didCloseErrorModal = async () => {
        isRecordTimeErrorModalOpen.value = false;
        isSuccessRecordedTimeModalOpen.value = false;
        clearPin();
        await router.replace({ name: "RecordTimes" });
    };

    const submit = async () => {
        try {
            isSubmitting.value = true;
            cancelTimeout();
            let base64PictureData: string | undefined = undefined;

            // Take picture if required
            if (shouldTakePicture.value) {
                base64PictureData = await cameraStore.takePicture();
            }

            recordedAssignments.value = await recordTimeService.recordTime(timeRecordingMode.value, pin.value, base64PictureData);
            isSuccessRecordedTimeModalOpen.value = true;
        } catch (e) {
            const axiosError = e as AxiosError;
            error.value = axiosError;

            const errorCode = (axiosError.response?.data as any)?.code;
            if (errorCode === RecordTimeErrorCode.UNKNOWN_PIN) {
                clearPin();
                toast(t("timeRecordingError.invalidPin"), {
                    type: "error",
                });
            } else {
                isRecordTimeErrorModalOpen.value = true;
            }
        } finally {
            isSubmitting.value = false;
            resetTimeout();
        }
    };

    const submitThreshold = computed(() => {
        return deviceStore.deviceConfig?.pinLength ?? 4;
    });

    // automatically submit when configured pin length is reached
    watch(pin, (newValue) => {
        if (newValue.length === submitThreshold.value) {
            submit();
        }
    });

    const shouldTakePicture = computed(() => {
        switch (timeRecordingMode.value) {
            case RecordTimesMode.RECORD_END_TIME:
            case RecordTimesMode.RECORD_START_TIME:
                return deviceStore.deviceConfig?.takePictureWhenClockingInOrOut ?? false;
            case RecordTimesMode.RECORD_BREAK_END_TIME:
            case RecordTimesMode.RECORD_BREAK_START_TIME:
                return false;
            default:
                return false;
        }
    });

    const timeRecordingModeColorClass = computed(() => {
        if (!isPinEntryReady.value) {
            return "bg-neutral-300";
        }
        switch (timeRecordingMode.value) {
            case RecordTimesMode.RECORD_END_TIME:
                return "bg-warning";
            case RecordTimesMode.RECORD_START_TIME:
                return "bg-primary";
            case RecordTimesMode.RECORD_BREAK_START_TIME:
            case RecordTimesMode.RECORD_BREAK_END_TIME:
                return "bg-secondary";
            default:
                return "";
        }
    });

    const timeRecordingModeIcon = computed(() => {
        switch (timeRecordingMode.value) {
            case RecordTimesMode.RECORD_END_TIME:
                return home;
            case RecordTimesMode.RECORD_START_TIME:
                return logIn;
            case RecordTimesMode.RECORD_BREAK_START_TIME:
                return cafe;
            case RecordTimesMode.RECORD_BREAK_END_TIME:
                return logOut;
            default:
                return "";
        }
    });
</script>
